@import './variables.scss';

#hero {
  background:url(../img/menuBackground.svg) no-repeat center center;
  background-size: cover;
  color:#fff;
  padding:30px 0;
  @media (min-width:$laptop) {
    background:url(../img/heroBackground.svg) no-repeat center center;
  }
  #button-border {
    @media (min-width:$laptop) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    button {
      border: 0;
      background: none;
      position: relative;
      outline: none;
      box-sizing: border-box;
      margin:30px 0;
      font-size:16px;
      font-weight:700;
      letter-spacing:.002rem;
      line-height:0.93;
      color:#fff;
      cursor:pointer;
      @media (min-width:$laptop) {
        font-size:30px;
        padding: 10px 40px;
        margin:30px auto;
      }
      &::before,
      &::after {
        @media (min-width:$laptop) {
          box-sizing: inherit;
          position: absolute;
          content: '';
          border: 1px solid transparent;
          width: 0;
          height: 0;
        }
      }
      &::after {
        @media (min-width:$laptop) {
          bottom: 0;
          right: 0;
        }
      }
      &::before {
        @media (min-width:$laptop) {
          top: 0;
          left: 0;
        }
      }
      &:hover {
        @media (min-width:$laptop) {
          &::before,
          &::after {
            width: 100%;
            height: 100%;
          }
          &::before {
            border-top-color: #fff;
            border-right-color: #fff;
            transition: width 0.15s ease-out, height 0.15s ease-out 0.15s;
          }
          &::after {
            border-bottom-color: #fff;
            border-left-color: #fff;
            transition: border-color 0s ease-out 0.3s, width 0.15s ease-out 0.3s, height 0.15s ease-out .5s;
          }
        }
      }
    }
  }

}