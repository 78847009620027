@import './variables.scss';
@import './mixins.scss';

.mad-lib-form {
	display:flex;
	flex-direction:column;
	background:$black;
	padding:75px 0;
	h2 {
		@include subhead();
		color:$light-purple;
	}
	h3 {
		font-family:$montserrat;
		font-size:28px;
		color:#fff;
		letter-spacing:.002rem;
		line-height:calc(40/28);
		text-align:center;
		margin-bottom:50px;
		@media (min-width:$laptop) {
			line-height:calc(23/28);
		}
	}
	form {
		label {
			color:#fff;
			text-transform:uppercase;
			text-align:center;
			width:100%;
			display:block;
			font-size:10px;
			font-weight:900;
			margin:5px auto 15px;
			letter-spacing:.17em;
			line-height:calc(22/15);
		}
		input {
			width:100%;
			&[type="text"] {
				border:0;
				color:$pink;
				background:transparent;
				border-bottom:1px solid #fff;
				font-size:16px;
				letter-spacing:.1em;
				text-align:center;
				line-height:2;
				outline:none;
				&:focus {
					border-bottom:1px solid $pink;
				}
			}
			&[type="submit"] {
				@include button();
				margin: 50px auto 20px;
				display:block;
				&:hover {
					@media (min-width:$laptop) {
						@include button-hover();
					}
				}
			}
		}
	}
}