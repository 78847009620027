@import './variables.scss';
@import './mixins.scss';

.mad-lib-result-images {
	display:flex;
	padding:40px 0;
	h3 {
		@include subhead();
	}
	h4 {
		font-family:$montserrat;
		font-size:18px;
		font-weight:600;
		letter-spacing: .002rem;
		line-height:calc(23/26);
	}
	.columns {
		display:flex;
		flex-direction:column;
		gap: 20px;
		text-align:center;
		width:100%;
		@media (min-width:$tablet) {
			flex-direction: row;
		}
		& > div {
			@media (min-width:$tablet) {
				width:calc(100%/3);
			}			
		}
	}
}
