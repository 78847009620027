@import './variables.scss';
@import './mixins.scss';

.mad-lib-result-text {
	display:flex;
	flex-direction:column;
	padding:40px 0;
	background:$black;
	color:#fff;
	h2 {
	    @include h1();
		&:after {
			content:"";
			border-bottom:2px solid $dark-purple;
			width:60px;
			height:2px;
			display:block;
			margin: 15px 0;
		    @media (min-width:$laptop) {
				margin: 15px auto;
		    }
		}
	    @media (min-width:$laptop) {
			text-align:center;
	        @include h1-desktop();
	    }
	}
	h3 {
		@include subhead();
	}
	p {
		font-size:16px;
		font-weight:600;
		@media (min-width:$laptop) {
			font-size:20px;
		}
		strong {
			font-size:22px;
			color:$medium-purple;
			font-weight:600;
			@media (min-width:$laptop) {
				font-size:26px;
			}
		}
	}
}
