@import './variables.scss';

@mixin h1 {
    font-family: $montserrat;
    font-size:20px;
    font-weight:600;
    letter-spacing:.036rem;
    line-height:calc(28/20);
}

@mixin h1-desktop {
    font-size:44px;
}

@mixin subhead {
	color:$dark-purple;
	text-align:center;
	text-transform:uppercase;
	font-size:12px;
	font-weight:900;
	letter-spacing:.22rem;
	line-height:calc(20/14);
	margin-bottom:30px;
}

@mixin button {
	background:url(../img/btnBackground.svg) no-repeat center center;
	background-size:cover;
	color:#fff;
	border-radius: 24px;
	width:auto;
	padding:15px 50px;
	margin: 20px auto;
	border:none;
	text-transform:uppercase;
	letter-spacing:.2em;
	font-weight:800;
	font-size: 12px;
	display:inline-block;
	text-decoration:none;
	font-family:$area;
	cursor:pointer;
}

@mixin button-hover {
	background:#fff;
	color:$dark-purple;
}

@mixin content-well {
	width:85%;
	margin:0 auto;
	@media (min-width:$tablet) {
		width:100%;
		max-width:600px;
	}
	@media (min-width:$laptop) {
		max-width:$tablet;
	}
	@media (min-width:$desktop) {
		max-width:904px;
	}
}