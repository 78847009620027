@import './variables.scss';

header {
	text-align:center;
	position:relative;
	padding-bottom:10px;
	@media (min-width:$laptop) {
		padding-bottom:0;
	}
	#alert-banner {
		background: #000;
		color:#fff;
		text-transform:uppercase;
		font-size:10px;
		line-height:22px;
		letter-spacing:.17em;
		font-weight:800;
		text-align:center;
		padding:7px 0;
	}
	#tagline {
		font-family: $verveine;
		margin-bottom:20px;
		font-size:20px;
		@media (min-width:$laptop) {

		}
	}
	#logo {
		margin:20px auto;
	}
}
